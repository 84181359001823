import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../../Tabels/Tabels.css";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../../common/ModelSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";
import JoditEditor from "jodit-react";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data } = useContext(Context);
  const [university, setUniversity] = useState([]);
  const [faq, setFaq] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetAllSpecilization = async (id) => {
    const response = await getData(`/allSpecialization/${id}`);
    console.log(faq);
    if (response.success) {
      setFaq(await Select2Data(response?.data, "specialization_id"));
    }
  };

  // useEffect(() => {
  //   GetAllFAQ();
  // }, []);

  const GetAllCourseUniversity = async () => {
    const response = await getData("/allCourseUniversity");

    if (response.success) {
      setUniversity(await Select2Data(response?.data, "course_university_id"));
    }
  };
  useEffect(() => {
    GetAllCourseUniversity();
  }, []);
  const {
    control,
    register,
    handleSubmit, getValues, setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const response = await postData(
        `/masters/ed-tech/home/fee-structure`,
        data
      );
      console.log(response);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(getValues(), "getValues");
  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Fee Structure
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
          // className="stateclass"
          >
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>University-Course</Form.Label>

                    <Controller
                      name="course_university_id" // name of the field
                      {...register("course_university_id", {
                        required: "course_university is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.course_university_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                            GetAllSpecilization(selectedOption.value);

                          }}
                          options={university}
                        />
                      )}
                    />

                    {errors.course_university_id && (
                      <span className="text-danger">
                        {errors.course_university_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Specialization</Form.Label>

                    <Controller
                      name="specialization_id" // name of the field
                      {...register("specialization_id", {
                        required: "Specialization is required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.specialization_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={faq}
                        />
                      )}
                    />

                    {errors.specialization_id && (
                      <span className="text-danger">
                        {errors.specialization_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Eligibility</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="eligibility"
                          placeholder="Eligibility"
                          className={classNames("", {
                            "is-invalid": errors?.eligibility,
                          })}
                          {...register("eligibility", {
                            required: "Eligibility is required",
                          })}
                        />
                      </InputGroup>
                      {errors.eligibility && (
                        <span className="text-danger">
                          {errors.eligibility.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col> */}

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Sem 1</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="sem_one"
                          placeholder="Sem One"
                          className={classNames("", {
                            "is-invalid": errors?.sem_one,
                          })}
                          {...register("sem_one", {
                            // required: "Sem 1 is required",
                          })}
                        />
                      </InputGroup>
                      {errors.sem_one && (
                        <span className="text-danger">
                          {errors.sem_one.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Sem 2</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="sem_two"
                          placeholder="Sem Two"
                          className={classNames("", {
                            "is-invalid": errors?.sem_two,
                          })}
                          {...register("sem_two", {
                            // required: "Sem 2 is required",
                          })}
                        />
                      </InputGroup>
                      {errors.sem_two && (
                        <span className="text-danger">
                          {errors.sem_two.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Sem 3</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="sem_three"
                          placeholder="Sem Three"
                          className={classNames("", {
                            "is-invalid": errors?.sem_three,
                          })}
                          {...register("sem_three", {
                            // required: "Sem 3 is required",
                          })}
                        />
                      </InputGroup>
                      {errors.sem_three && (
                        <span className="text-danger">
                          {errors.sem_three.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Sem 4</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="sem_four"
                          placeholder="Sem Four"
                          className={classNames("", {
                            "is-invalid": errors?.sem_four,
                          })}
                          {...register("sem_four", {
                            // required: "Sem 4 is required",
                          })}
                        />
                      </InputGroup>
                      {errors.sem_four && (
                        <span className="text-danger">
                          {errors.sem_four.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Sem 5</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="sem_five"
                          placeholder="Sem Five"
                          className={classNames("", {
                            "is-invalid": errors?.sem_five,
                          })}
                          {...register("sem_five", {
                            // required: "Sem 5 is required",
                          })}
                        />
                      </InputGroup>
                      {errors.sem_five && (
                        <span className="text-danger">
                          {errors.sem_five.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Sem 6</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="sem_six"
                          placeholder="Sem Six"
                          className={classNames("", {
                            "is-invalid": errors?.sem_six,
                          })}
                          {...register("sem_six", {
                            // required: "Sem 6 is required",
                          })}
                        />
                      </InputGroup>
                      {errors.sem_six && (
                        <span className="text-danger">
                          {errors.sem_six.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Scholarship</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="scholarship"
                          placeholder="Scholarship"
                          className={classNames("", {
                            "is-invalid": errors?.scholarship,
                          })}
                          {...register("scholarship", {
                            // required: "Scholarship is required",
                          })}
                        />
                      </InputGroup>
                      {errors.scholarship && (
                        <span className="text-danger">
                          {errors.scholarship.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col> */}

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Total Fee</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="total_fee"
                          placeholder="Total Fee"
                          className={classNames("", {
                            "is-invalid": errors?.total_fee,
                          })}
                          {...register("total_fee", {
                            // required: "Total Fee is required",
                          })}
                        />
                      </InputGroup>
                      {errors.total_fee && (
                        <span className="text-danger">
                          {errors.total_fee.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Annual Fee</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="annual_fee"
                          placeholder="Annual Fee"
                          className={classNames("", {
                            "is-invalid": errors?.annual_fee,
                          })}
                          {...register("annual_fee", {
                            // required: "Annual Fee is required",
                          })}
                        />
                      </InputGroup>
                      {errors.annual_fee && (
                        <span className="text-danger">
                          {errors.annual_fee.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Actual Fee</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="actual_fee"
                          placeholder="Actual Fee"
                          className={classNames("", {
                            "is-invalid": errors?.actual_fee,
                          })}
                          {...register("actual_fee", {
                            // required: "Actual Fee is required",
                          })}
                        />
                      </InputGroup>
                      {errors.actual_fee && (
                        <span className="text-danger">
                          {errors.actual_fee.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Discounted One Time Fee</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="discounted_one_time_fee"
                          placeholder="Discounted One Time Fee"
                          className={classNames("", {
                            "is-invalid": errors?.discounted_one_time_fee,
                          })}
                          {...register("discounted_one_time_fee", {
                            // required: "Discounted One Time Fee is required",
                          })}
                        />
                      </InputGroup>
                      {errors.discounted_one_time_fee && (
                        <span className="text-danger">
                          {errors.discounted_one_time_fee.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Early Bird Discount (%)</Form.Label>

                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="early_bird_discount"
                          max={100}
                          placeholder="Early Bird Fee"
                          className={classNames("", {
                            "is-invalid": errors?.early_bird_discount,
                          })}
                          {...register("early_bird_discount", {
                            // required: "Early Bird Fee is required",
                            validate: {
                              positive: v => v >= 0 || "Value must be at least 0",
                              max: v => v <= 100 || "Value must be at most 100",
                            },
                          })}
                          onBlur={(e) => {
                            if (e.target.value > 100) {
                              e.target.value = 100;
                            } else if (e.target.value < 0) {
                              e.target.value = 0;
                            }
                          }}
                          onKeyDown={(event) => {
                            const { key } = event;
                            if (!/[0-9]/.test(key) && key !== "Backspace" && key !== "Tab") {
                              event.preventDefault();
                            }
                            if (event.target.value?.length >= 3 && key !== "Backspace" && key !== "Tab") {
                              event.preventDefault();
                            }
                          }}
                        />
                      </InputGroup>
                      {errors.early_bird_discount && (
                        <span className="text-danger">
                          {errors.early_bird_discount.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
